import React from "react";
import mq from "../ui/media-queries";
import {Text} from "../ui/Text";
import Strip from "../ui/Strip";

const sizeToProps = {
  xl: {
    size: 7,
    at: {[mq.sm]: {size: 6}},
  },
  lg: {
    size: 6,
    at: {[mq.sm]: {size: 5}},
  },
  md: {
    size: 5,
    at: {[mq.sm]: {size: 4}},
  },
};

const sublineSizeToProps = {
  xl: {
    size: 5,
  },
  lg: {
    size: 4,
  },
  md: {
    size: 3,
  },
};

const Heading = ({size = "lg", dark, children, id, subline}) => (
  <Strip size="sm" pt={8} pb={6} bg={dark ? "gray900" : "white"} sp={3} id={id}>
    <Text
      as="h2"
      preset="bold"
      align="center"
      color={dark ? "white" : "gray800"}
      {...sizeToProps[size]}
    >
      {children}
    </Text>
    {subline && (
      <Text align="center" color={dark ? "gray100" : "gray700"} {...sublineSizeToProps[size]}>
        {subline}
      </Text>
    )}
  </Strip>
);

export default Heading;
