import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";
import Heading from "../components/bricks/Heading";
import MultiFeature from "../components/bricks/MultiFeature";
import ThemedSingleFeature from "../components/bricks/ThemedSingleFeature";
import {themeBright} from "../page-styles/landingpage.css";
import ThemeBox from "../components/bricks/ThemeBox";
import useCounters from "../components/useCounters";
import {ThemedButton} from "../components/xui/ThemedButton";
import {Box, XCol, XRow} from "../components/xui/Box";
import Ui from "../components/Ui";
import cx from "../components/xui/cx";
import {uiStyles} from "../components/xui/xui.css";

const Community = ({location}) => (
  <DefaultLayout
    title="Community Hub"
    description="Community-made resources for Codecks"
    location={location}
  >
    <Hero
      size="sm"
      title="Community Hub"
      subline="We are in it together. "
      bottomImg="magician.svg"
    />

    <Heading
      dark
      subline={
        <>
          Cool extensions by our awesome community, built with our{" "}
          <Ui.Link href="https://manual.codecks.io/api/">API</Ui.Link>.
        </>
      }
    >
      Community Extensions
    </Heading>

    <MultiFeature
      dark
      features={[
        {
          img: "community/community-godot-2.png",
          lead: "Godot",
          title: "Godot Editor Plugin",
          description: "Use Codecks in Godot. Project by Feendrache.",
          button: {
            label: "Gitlab Project",
            url: "https://gitlab.com/schwingler_nadine/godot-editor-plugin-for-codecks",
          },
        },
        {
          img: "community/community-unity.png",
          lead: "Unity",
          title: "Unity Integration",
          description:
            "Annotate Unity scenes with Codecks cards in 3d space. Project by Ciro Continisio",
          button: {
            label: "Check Preview",
            url: "https://www.youtube.com/watch?v=dif0u-S9GYo",
          },
        },
        {
          img: "community/community-plastic.png",
          lead: "PlasticSCM",
          title: "Codecks Plastic Integration",
          description: "Use PlasticSCM with Codecks. Project by Chris Yarbrough",
          button: {
            label: "Github Project",
            url: "https://github.com/chrisyarbrough/CodecksPlasticIntegration",
          },
        },
        {
          img: "community/community-godot-1.png",
          lead: "Godot",
          title: "Codecks User Reports for Godot",
          description: "Report bugs and feedback from within your Godot game. Project by vnznznz",
          button: {
            label: "Github Project",
            url: "https://github.com/vnznznz/codecks-user-reports-godot",
          },
        },
      ]}
    />

    <ThemeBox theme={themeBright}>
      <ThemedSingleFeature
        title="Our Discord Community 💜"
        img="community/community-decky.png"
        description={<>Scoreboard for our 10 most active members</>}
      >
        <MemberOverview />
        <ThemedButton to="https://discord.gg/NFkvjg6">Join our Discord</ThemedButton>
      </ThemedSingleFeature>
    </ThemeBox>

    <Heading dark subline="Some of our favorite videos featuring Codecks">
      Videos
    </Heading>

    <MultiFeature
      dark
      features={[
        {
          img: "community/community-youtube-official.png",
          lead: "Codecks",
          title: "Our YouTube Channel",
          description: "Our channel is choke-full of content helping you to master Codecks.",
          imgUrl: "https://www.youtube.com/c/Codecks",
        },
        {
          img: "community/community-youtube-2.png",
          lead: "n8dev",
          title: "How I'm Using Codecks to plan my Indie Game",
          // description: "Info about Game Dev 1",
          imgUrl:
            "https://www.youtube.com/watch?v=3nIDqM3JSbE&list=PLBtEg45VQuYZg21Dxc0PQnXolKLwD-mZj&index=4",
        },
        {
          img: "community/community-youtube-1.png",
          lead: "Logic Leo",
          title: "How to plan and organize your indie game project",
          // description: "Info about Game Dev 1",
          imgUrl:
            "https://www.youtube.com/watch?v=i1rZtoqguFM&list=PLBtEg45VQuYZg21Dxc0PQnXolKLwD-mZj&index=10",
        },
      ]}
    />

    <Heading>There's more...</Heading>

    <MultiFeature
      features={[
        {
          img: "community/community-showcase.jpg",
          imgUrl: "/showcase/",
          title: "Showcase",
          description:
            "Check out our showcase to see amazing games that have been created with Codecks.",
        },
        {
          img: "next/open-development.png",
          title: "Open Decks",
          imgUrl: "https://open.codecks.io/",
          description:
            "Check out our open decks gallery to see games which are sharing their progress publicly.",
        },
        {
          img: "community/community-steamy.png",
          title: "Steamy",
          imgUrl: "/steam-bot/",
          description:
            "Steamy is a free discord bot posting your Steam reviews to your community automatically.",
        },
      ]}
    />
  </DefaultLayout>
);

const MemberRow = ({name, score, avatar, userId}) => (
  <XRow sp={2} align="center">
    {avatar ? (
      <img
        src={`https://cdn.discordapp.com/avatars/${userId}/${avatar}.png`}
        width={24}
        height={24}
        alt={""}
        className={cx(
          uiStyles.display.block,
          uiStyles.rounded.sm,
          uiStyles.borderWidth[1],
          uiStyles.borderColor.bgBorder,
          uiStyles.elevation.sm
        )}
      />
    ) : (
      <div style={{width: 24, height: 24}} />
    )}
    <Box color="body" size="md" fillParent>
      {name}
    </Box>
    <Box color="body" size="lg" bold>
      {score}
    </Box>
  </XRow>
);

const MemberOverview = () => {
  const {discord_members} = useCounters();
  if (discord_members.length === 0) {
    return (
      <Box color="body" size="lg" bold>
        No data available
      </Box>
    );
  }
  return (
    <XCol sp={1} width="100%">
      {discord_members.map(m => (
        <MemberRow {...m} key={m.userId} />
      ))}
    </XCol>
  );
};

export default Community;
